import { css } from 'styled-components';

export const transition = (duration: string, curve: string, ...properties: string[]) => css`
  transition: ${properties.join(', ')};
  transition-duration: ${duration};
  transition-timing-function: ${curve};
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
