import { useState, useEffect, useCallback } from "react";
import { API, Logger, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { atom, useRecoilState } from "recoil";
import { useRoute } from "@react-navigation/native";

import { Call, CallParticipant } from "../types/models";
import { getCall } from "../../graphql/queries";
import {
  AttendeeJoinedSubscriptionVariables,
  GetCallQuery,
} from "../../api";
import { attendeeJoined } from "../../graphql/subscriptions";

const logger = new Logger("Tazet");

const callState = atom<Call | null>({
  key: "models.call",
  default: null,
});

const fetchCall = async (id: string, secret: string): Promise<Call> => {
  try {
    const query = getCall;
    const authToken = `${id}:${secret}`;

    const response = (await API.graphql({
      query,
      authToken,
    })) as GraphQLResult<GetCallQuery>;

    const call = response?.data?.getCall;

    if (!call) {
      throw new Error("Unable to fetch call");
    }

    const host = {
      id: call.host.id,
      name: call.host.profile.alias,
      joined: call.host.joined ? new Date(call.host.joined) : null,
    };

    const attendee = {
      id: call.attendee.id,
      name: call.attendee.name.first,
      joined: call.attendee.joined ? new Date(call.attendee.joined) : null,
    };

    return {
      id: call.id,
      subject: call.subject ?? "",
      host,
      attendee,
      start: new Date(call.start),
      duration: call.duration,
      cancelled: call.cancelled,
    };
  } catch (err) {
    logger.error("Error fetching call by ID", err);

    throw err;
  }
};

const subscribeToCallParticipants = (
  callId: string,
  callSecret: string,
  onJoined: (participants: CallParticipants) => void
): any => {
  const query = attendeeJoined;
  const variables = { callId } as AttendeeJoinedSubscriptionVariables;
  const authToken = `${callId}:${callSecret}`;

  const subscription = API.graphql(
    graphqlOperation(query, variables, authToken)
  ).subscribe({
    next: ({ value }: any) => {
      const call = value.data?.attendeeJoined?.call;

      const host = {
        id: call.host.id,
        name: call.host.profile.alias,
        joined: call.host.joined ? new Date(call.host.joined) : null,
      };

      const attendee = {
        id: call.attendee.id,
        name: call.attendee.name.first,
        joined: call.attendee.joined ? new Date(call.attendee.joined) : null,
      };

      onJoined({ host, attendee });
    },
    error: logger.error,
  });

  return subscription;
};

type FetchCallResult = [call: Call | null, loaded: boolean];

const useCall = (): FetchCallResult => {
  const route = useRoute();
  const [call, setCall] = useRecoilState<Call | null>(callState);
  const [loaded, setLoaded] = useState(false);

  const success = useCallback(
    (call: Call | null) => {
      setCall(call);
      setLoaded(true);
    },
    [setCall, setLoaded]
  );

  useEffect(() => {
    if (call || loaded) {
      return;
    }

    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };

    fetchCall(callId, callSecret).then(success);
  }, [route, call, loaded, success]);

  return [call, !!call || loaded];
};

type CallParticipants = {
  host: CallParticipant;
  attendee: CallParticipant;
};
type CallParticipantsResult = [
  participants: CallParticipants | null,
  loaded: boolean
];

const useCallParticipants = (): CallParticipantsResult => {
  const route = useRoute();
  const [participants, setParticipants] = useState<CallParticipants | null>(
    null
  );
  const [loaded, setLoaded] = useState(false);

  const success = useCallback(
    (call: Call) => {
      setParticipants({ host: call.host, attendee: call.attendee });
      setLoaded(true);
    },
    [setParticipants, setLoaded]
  );

  useEffect(() => {
    if (loaded) {
      return;
    }

    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };

    fetchCall(callId, callSecret).then(success);
  }, [route, loaded, setParticipants]);

  useEffect(() => {
    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };
    const subscription = subscribeToCallParticipants(
      callId,
      callSecret,
      setParticipants
    );

    return () => {
      try {
        subscription.unsubscribe();
      } catch (e) {
        logger.error("Error unsubscribing from call", e);
      }
    };
  }, [route, loaded, setParticipants]);

  return [participants, loaded];
};

export { useCall, useCallParticipants };
