import { Image } from "react-native";
import styled from "styled-components/native";
import { fontBaseRegular, fontBaseSemiBold } from "../../../styles";

const StyledContainer = styled.View`
  background-color: ${({ theme }) => theme.palette.base10};
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: -200px;
`;

const Header = styled.Text`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.base1};
  margin-top: 26px;
  ${fontBaseSemiBold};
`;

const Body = styled.Text`
  color: ${({ theme }) => theme.palette.base3};
  font-size: 16px;
  margin-top: 4px;
  padding: 16px;
  text-align: center;
  ${fontBaseRegular};
`;

const Actions = styled.View`
  margin-top: 24px;
`;

interface PageMessageProps {
  title: string;
  message?: React.ReactNode;
  children?: React.ReactNode;
}

const PageMessage = ({ title, message, children }: PageMessageProps) => (
  <StyledContainer>
    <Image
      style={{ width: "62px", height: "44px" }}
      source={require("../../../assets/images/tz-logomark--dark.png")}
    />
    <Header>{title}</Header>
    <Body>{message}</Body>
    <Actions>{children}</Actions>
  </StyledContainer>
);

export default PageMessage;
