import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import { CallValidator } from "../../common/components/CallValidator/index.web";
import PageMessage from "../../common/components/PageMessage";
import { Route } from "../../navigators/root";

const CallEarly: React.FC<{ isInCallWindow: boolean }> = ({
  isInCallWindow,
}) => {
  const route = useRoute();
  const navigation = useNavigation();

  useEffect(() => {
    // @ts-ignore
    if (isInCallWindow) navigation.navigate(Route.Preview, route.params);
  }, [isInCallWindow]);

  return (
    <PageMessage
      title="Looks like you're a little early."
      message="Check back here 10 minutes before this call's scheduled start time."
    />
  );
};

const CallEarlyWrapper: React.FC = () => (
  <CallValidator>
    {({ isEarly, isEnded }) => (
      <CallEarly isInCallWindow={!isEarly && !isEnded} />
    )}
  </CallValidator>
);

export default CallEarlyWrapper;
