import { keyframes } from 'styled-components';

export const buttonTwurlDotGlow = keyframes`
  0%    {  opacity: 0.1  }
  10%   {  opacity: 1    }
  90%   {  opacity: 0.1  }
  100%  {  opacity: 0.1  }
`;

export const notificationIn = keyframes`
  0%    {  transform: translateY(-100px) }
  100%  {  transform: translateY(0px)    }
`;

export const notificationOut = keyframes`
  0%    {  transform: translateY(0px)    }
  100%  {  transform: translateY(-100px) }
`;

export const wiggle = keyframes`
  0%    { transform: rotateZ(-5deg) }
  3%    { transform: rotateZ(5deg)  }
  6%    { transform: rotateZ(-4deg) }
  9%    { transform: rotateZ(4deg)  }
  12%   { transform: rotateZ(-3deg) }
  15%   { transform: rotateZ(3deg)  }
  18%   { transform: rotateZ(-2deg) }
  21%   { transform: rotateZ(0deg)  }
  100%  { transform: rotateZ(0deg)  }
`;


export const fadeIn = keyframes`
  0%    { opacity: 0 }
  100%  { opacity: 1 }
`