import { Text } from "react-native";
import { CallJoinOverride } from "../../common/components/CallJoinOverride/index.web";
import Link from "../../common/components/Link";
import PageMessage from "../../common/components/PageMessage";

const CallEnded = () => (
  <>
    <PageMessage
      title="This call has ended."
      message={
        <>
          <Text>Submit any feedback or issues to </Text>
          <Link to="mailto:support@tazet.com">support@tazet.com</Link>
        </>
      }
    />
    <CallJoinOverride />
  </>
);

export default CallEnded;
