import {
  StackActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { useLocalVideo } from "@tazet/react-native-chime/src/index.web";
import { useEffect } from "react";
import Button from "../../common/components/ButtonWeb/index.web";
import { CallJoinOverride } from "../../common/components/CallJoinOverride/index.web";
import PageMessage from "../../common/components/PageMessage";
import { Route } from "../../navigators/root";

const CallExited = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (isVideoEnabled) toggleVideo();
  }, [isVideoEnabled]);

  return (
    <PageMessage
      title="You have left the call."
      message="You can return to the call anytime before the call’s end time."
    >
      <Button
        onClick={() => {
          navigation.dispatch(StackActions.replace(Route.Call, route.params));
        }}
        label="Return to call"
        inline
        variant="outlined"
      />
      <CallJoinOverride />
    </PageMessage>
  );
};

export default CallExited;
