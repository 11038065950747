import styled, { Keyframes } from "styled-components";
import classnames from "classnames";
import { transition } from "../../../styles/mixins/helpers";
import { buttonTwurlDotGlow } from "../../../styles/mixins/animations";

type TwurlVariant = "light" | "dark";

const Twurl: React.FC<{
  animation?: Keyframes;
  visible?: boolean;
  variant?: TwurlVariant;
}> = ({ animation = buttonTwurlDotGlow, visible = true, variant = "dark" }) => (
  <StyledTwurl
    $animation={animation}
    className={classnames("twurl", { visible, [variant]: variant })}
  >
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
    <div className="dot" />
  </StyledTwurl>
);

const StyledTwurl = styled.div<{ $animation: Keyframes }>`
  left: calc(50% - 9px);
  top: calc(50% - 10px);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  ${({ theme }) =>
    transition(theme.duration.time4, theme.easing.easeOutMax, "opacity")};
  width: 19px;
  height: 19px;

  div.dot {
    border-radius: 50%;
    position: absolute;
    width: 3px;
    height: 3px;

    &:nth-child(1) {
      left: 8px;
      top: 0;
      animation: ${({ $animation }) => $animation} 1s linear 913ms infinite;
    }
    &:nth-child(2) {
      left: 4px;
      top: 1px;
      animation: ${({ $animation }) => $animation} 1s linear 830ms infinite;
    }
    &:nth-child(3) {
      left: 1px;
      top: 4px;
      animation: ${({ $animation }) => $animation} 1s linear 747ms infinite;
    }
    &:nth-child(4) {
      left: 0px;
      top: 8px;
      animation: ${({ $animation }) => $animation} 1s linear 664ms infinite;
    }
    &:nth-child(5) {
      left: 1px;
      top: 12px;
      animation: ${({ $animation }) => $animation} 1s linear 581ms infinite;
    }
    &:nth-child(6) {
      left: 4px;
      top: 15px;
      animation: ${({ $animation }) => $animation} 1s linear 498ms infinite;
    }
    &:nth-child(7) {
      left: 8px;
      top: 16px;
      animation: ${({ $animation }) => $animation} 1s linear 415ms infinite;
    }
    &:nth-child(8) {
      left: 12px;
      top: 15px;
      animation: ${({ $animation }) => $animation} 1s linear 332ms infinite;
    }
    &:nth-child(9) {
      left: 15px;
      top: 12px;
      animation: ${({ $animation }) => $animation} 1s linear 249ms infinite;
    }
    &:nth-child(10) {
      left: 16px;
      top: 8px;
      animation: ${({ $animation }) => $animation} 1s linear 166ms infinite;
    }
    &:nth-child(11) {
      left: 15px;
      top: 4px;
      animation: ${({ $animation }) => $animation} 1s linear 83ms infinite;
    }
    &:nth-child(12) {
      left: 12px;
      top: 1px;
      animation: ${({ $animation }) => $animation} 1s linear 0ms infinite;
    }
  }

  &.visible {
    opacity: 1;
  }

  &.light div.dot {
    background-color: ${({ theme }) => theme.palette.base11};
  }

  &.dark div.dot {
    background-color: ${({ theme }) => theme.palette.base3};
  }
`;

export { Twurl };
