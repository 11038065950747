import { useState, useEffect } from 'react';
import {
    requestCameraPermissionsAsync,
    requestMicrophonePermissionsAsync,
    PermissionStatus
} from 'expo-camera';

type PermissionsResult = {
    camera: PermissionStatus;
    microphone: PermissionStatus;
};

const usePermissions = (): PermissionsResult | null => {
    const [camera, setCamera] = useState<PermissionStatus | null>(null);
    const [microphone, setMicrophone] = useState<PermissionStatus | null>(null);

    useEffect(() => {
        // if we've received any permission responses,
        // we're either done or the other is in-flight
        if (camera !== null || microphone !== null) {
            return;
        }

        const requestCamera = async () => {
            const { status } = await requestCameraPermissionsAsync();
            setCamera(status);
        };

        const requestMicrophone = async () => {
            const { status } = await requestMicrophonePermissionsAsync();
            setMicrophone(status);
        };

        Promise.all([requestCamera(), requestMicrophone()]);
    }, [
        camera,
        setCamera,
        microphone,
        setMicrophone,
    ]);

    return {
        camera: camera ?? PermissionStatus.UNDETERMINED,
        microphone: microphone ?? PermissionStatus.UNDETERMINED,
    };
};

export { usePermissions };