import { css } from "styled-components";

export const fontBaseRegular = css`
  font-family: "Mada_400Regular";
  font-weight: 400;
`;

export const fontBaseMedium = css`
  font-family: "Mada_500Medium";
  font-weight: 500;
`;

export const fontBaseSemiBold = css`
  font-family: "Mada_600SemiBold";
  font-weight: 600;
`;
