import { useCallback, useState } from "react";
import { useInterval } from "./useInterval";

interface CountdownOption {
  countStart: number;
  intervalMs?: number;
  isIncrement?: boolean;
  countStop?: number;
  autoStart?: boolean;
}
interface CountdownControllers {
  startCountdown: () => void;
  stopCountdown: () => void;
  resetCountdown: () => void;
  updateCountdown: (count: number) => void;
}

export const useCountdown = (
  countdownOption: CountdownOption
): [number, CountdownControllers] => {
  const {
    countStart,
    intervalMs = 1000,
    isIncrement = false,
    countStop = 0,
    autoStart = true,
  } = countdownOption;

  const [count, setCount] = useState(countStart);
  const [isCountdownRunning, setIsCountdownRunning] = useState(autoStart);

  const resetCountdown = () => {
    setIsCountdownRunning(false);
    setCount(countStart);
  };

  const updateCountdown = (count: number) => {
    setIsCountdownRunning(false);
    setCount(count);
    setIsCountdownRunning(true);
  };

  const countdownCallback = useCallback(() => {
    if (count === countStop) {
      setIsCountdownRunning(false);
      return;
    }

    if (isIncrement) setCount((c) => c + 1);
    else setCount((c) => c - 1);
  }, [count, countStop, setIsCountdownRunning, setCount, isIncrement]);

  useInterval(countdownCallback, isCountdownRunning ? intervalMs : null);

  return [
    count,
    {
      startCountdown: () => setIsCountdownRunning(true),
      stopCountdown: () => setIsCountdownRunning(false),
      updateCountdown,
      resetCountdown,
    },
  ];
};
