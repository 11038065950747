import { API, Logger } from "aws-amplify";
import styled from "styled-components";
import { useNavigation, useRoute } from "@react-navigation/native";
import moment from "moment";
import { useEffect, useState } from "react";
import { ActivityIndicator } from "react-native";
import Button from "../../common/components/ButtonWeb/index.web";
import PageMessage from "../../common/components/PageMessage";
import { useCall, useIdentity } from "../../common/hooks";
import { Route } from "../../navigators/root";
import { CancelCallLoading } from "./styled.web";
import { TextArea } from "../../common/components/TextArea/index.web";
import { cancelCall } from "../../graphql/mutations";
import { CancelCallMutationVariables } from "../../api";

const logger = new Logger("Tazet");

type CancelCallStatusType = "init" | "valid" | "expired" | "success";

const Form = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CancelCall = () => {
  const route = useRoute();
  const { c: id, s: secret } = route.params as { c: string; s: string };

  const [call] = useCall();
  const [identity] = useIdentity();
  const navigation = useNavigation();
  const [isCanceling, setIsCanceling] = useState(false);
  const [cancelCallStatus, setCancelCallStatus] =
    useState<CancelCallStatusType>("init");

  useEffect(() => {
    if (call) {
      if (call.cancelled) {
        setCancelCallStatus("success");
        return;
      }
      const bufferDiff = moment(call.start).diff(moment().add(8, "hours"));
      const endDiff = moment(call.start)
        .add(moment.duration(call.duration))
        .diff(moment());

      // @ts-ignore
      if (endDiff <= 0) navigation.navigate(Route.Ended, route.params);
      else if (bufferDiff > 0) setCancelCallStatus("valid");
      else if (bufferDiff <= 0) setCancelCallStatus("expired");
    }
  }, [call]);

  const isHost = identity && call && identity.id === call.host.id;
  const nameCtx =
    identity && call ? (isHost ? call.attendee.name : call.host.name) : "";

  return call && identity ? (
    <PageMessage
      title={
        cancelCallStatus === "success"
          ? "Call canceled"
          : `Cancel your call with ${nameCtx}`
      }
      message={
        <>
          {cancelCallStatus !== "success" &&
            (isHost
              ? "(they will receive a full refund)"
              : cancelCallStatus === "expired"
              ? "Since this call is in less than 8 hours, you will not receive refund."
              : "You will receive a full refund.")}
          {cancelCallStatus === "success" && "This call has been canceled."}
        </>
      }
    >
      {cancelCallStatus !== "success" && (
        <Form
          onSubmit={async (e) => {
            e.preventDefault();

            if (id && secret) {
              const note =
                (
                  document.getElementById(
                    "cancel-call-notes"
                  ) as HTMLTextAreaElement
                ).value ?? "";
              try {
                setIsCanceling(true);
                await API.graphql({
                  query: cancelCall,
                  variables: {
                    input: { note },
                  } as CancelCallMutationVariables,
                  authToken: `${id}:${secret}`,
                });
                setCancelCallStatus("success");
              } catch (e) {
                logger.error("Error cancelling call", e);
              } finally {
                setIsCanceling(false);
              }
            }
          }}
        >
          <TextArea
            id="cancel-call-notes"
            placeholder={`Tell ${nameCtx} why you are canceling this call (optional)`}
          />
          <Button
            label="Cancel Call"
            type="submit"
            loading={isCanceling}
            disabled={isCanceling}
          />
        </Form>
      )}
    </PageMessage>
  ) : (
    <CancelCallLoading>
      <ActivityIndicator />
    </CancelCallLoading>
  );
};

export default CancelCall;
