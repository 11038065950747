import { useRoute } from "@react-navigation/native";
import { API, Logger } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { getIdentity as query } from "../../graphql/queries";
import { Identity, GetIdentityQuery } from "../types/identity";

const logger = new Logger("Tazet");

const identityState = atom<Identity | null>({
  key: "identity",
  default: null,
});

export const useIdentity = () => {
  const route = useRoute();
  const [identity, setIdentity] = useRecoilState(identityState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (identity || loaded) return;

    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };

    fetchIdentity(callId, callSecret).then((identity) => {
      setIdentity(identity);
      setLoaded(true);
    });
  }, []);

  return [identity];
};

const fetchIdentity = async (id: string, secret: string): Promise<Identity> => {
  try {
    const authToken = `${id}:${secret}`;
    const response = (await API.graphql({
      query,
      authToken,
    })) as GraphQLResult<GetIdentityQuery>;
    const identity = response?.data?.whoami;

    if (!identity) throw new Error("Unable to fetch identity");
    else return identity;
  } catch (err) {
    logger.error("Error fetching user identity", err);
    throw err;
  }
};
