import styled from "styled-components";
import {
  StackActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { Route } from "../../../navigators/root";
import { isDev } from "../../consts";
import { fontBaseRegular } from "../../../styles";

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 10px;
  left: 10px;
  padding: 10px;
`;

const Link = styled.a`
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  ${fontBaseRegular};
  :hover {
    background: ${({ theme }) => theme.palette.base11};
  }
`;

export const CallJoinOverride = () => {
  const route = useRoute();
  const navigation = useNavigation();

  return isDev ? (
    <Wrapper>
      <Link
        onClick={() =>
          navigation.dispatch(
            StackActions.replace(Route.Call, {
              ...route.params,
              override: true,
            })
          )
        }
      >
        Join Call Override
      </Link>
    </Wrapper>
  ) : null;
};
