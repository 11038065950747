import styled from "styled-components";
import { fontBaseRegular } from "../../../styles";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label?: string;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  label,
  placeholder = "",
  ...rest
}) => (
  <TextAreaWrapper className="input-field">
    {label ? <label htmlFor={id}>{label}</label> : null}
    <textarea id={id} placeholder={placeholder} {...rest} />
  </TextAreaWrapper>
);

const TextAreaWrapper = styled.div`
  & + &.input-field {
    margin-top: 16px;
  }

  label {
    color: ${({ theme }) => theme.palette.base5};
    display: inline-block;
    font-size: 14px;
    margin-bottom: 7px;
  }

  textarea {
    ${fontBaseRegular};
    resize: none;
    background-color: ${({ theme }) => theme.palette.base11};
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    color: ${({ theme }) => theme.palette.base1};
    display: block;
    font-size: 16px;
    padding: 16px 22px 16px;
    position: relative;
    transition: background-color linear 2147483647s; // prevents chrome auto-coloring
    width: 100%;

    // If number, hide arrows.
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &:focus {
      border-left: 1px solid ${({ theme }) => theme.palette.base1};
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04),
        inset 1px 0 ${({ theme }) => theme.palette.base1};
    }

    &::placeholder {
      ${fontBaseRegular};
      color: ${({ theme }) => theme.palette.base6};
      font-size: 14px;
    }
  }
`;

export { TextArea };
