import React, { Suspense } from "react";
import { ActivityIndicator } from "react-native";
import styled from "styled-components/native";
import { createStackNavigator } from "@react-navigation/stack";

import NotFoundScreen from "../screens/not-found";
import NotStartedScreen from "../screens/not-started";
import EndedScreen from "../screens/ended";
import ExitedScreen from "../screens/exited";
import CancelCallScreen from "../screens/cancel/index.web";

enum Route {
  Preview = "preview",
  Call = "call",
  NotFound = "not-found",
  NotStarted = "not-started",
  Ended = "ended",
  Exited = "exited",
  Cancel = "cancel",
}

type CallParams = {
  c?: string; // call id
  s?: string; // call secret
};

// https://reactnavigation.org/docs/typescript/#type-checking-the-navigator
type StackParamList = {
  [Route.Preview]: CallParams | undefined;
  [Route.Call]: CallParams | undefined;
  [Route.Ended]: CallParams | undefined;
  [Route.NotFound]: CallParams | undefined;
  [Route.NotStarted]: CallParams | undefined;
  [Route.Exited]: CallParams | undefined;
  [Route.Cancel]: CallParams | undefined;
};

const Stack = createStackNavigator<StackParamList>();

const Container = styled.View`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.base10};
  align-items: center;
  justify-content: center;
`;

const LoadingScreen = () => (
  <Container>
    <ActivityIndicator />
  </Container>
);

const LazyPreviewScreen = React.lazy(
  () => import("../screens/preview/index.web")
);
const LazyCallScreen = React.lazy(() => import("../screens/call/index.web"));

const LoadablePreviewScreen = () => (
  <Suspense fallback={<LoadingScreen />}>
    <LazyPreviewScreen />
  </Suspense>
);

const LoadableCallScreen = () => (
  <Suspense fallback={<LoadingScreen />}>
    <LazyCallScreen />
  </Suspense>
);

const RootNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, detachPreviousScreen: true }}
    >
      <Stack.Screen name={Route.NotFound} component={NotFoundScreen} />
      <Stack.Screen name={Route.Preview} component={LoadablePreviewScreen} />
      <Stack.Screen name={Route.Call} component={LoadableCallScreen} />
      <Stack.Screen name={Route.Ended} component={EndedScreen} />
      <Stack.Screen name={Route.Exited} component={ExitedScreen} />
      <Stack.Screen name={Route.NotStarted} component={NotStartedScreen} />
      <Stack.Screen name={Route.Cancel} component={CancelCallScreen} />
    </Stack.Navigator>
  );
};

export { Route, StackParamList };
export default RootNavigator;
