import { useEffect } from "react";
import { useMeetingManager } from "@tazet/react-native-chime/src/index.web";
import { VideoCodecCapability } from "amazon-chime-sdk-js";

export const useAudioVideoPreferences = () => {
  const { audioVideo, meetingSession } = useMeetingManager();

  useEffect(() => {
    if (audioVideo) {
      console.log("PREF: Setting video preferences");
      audioVideo.setVideoCodecSendPreferences?.([VideoCodecCapability.vp8()]);
      audioVideo.setContentShareVideoCodecPreferences?.([VideoCodecCapability.vp8()]);
    }
  }, [audioVideo]);

  useEffect(() => {
    if (meetingSession) {
      console.log("PREF: Setting video quality preferences");
      meetingSession.audioVideo.chooseVideoInputQuality(640, 360, 15);
      meetingSession.audioVideo.setVideoMaxBandwidthKbps(2500);
    }
  }, [meetingSession]);

  return null;

};
