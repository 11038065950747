import { useState, useEffect } from "react";
import { Logger } from "aws-amplify";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { ChimeJoinInfo } from "../types/chime";
import { useMeetingManager } from "@tazet/react-native-chime/src/index.web";

const logger = new Logger("Tazet.Meeting");

type JoinStatus = "ready" | "joining" | "joined" | "started" | "failed";

const useChimeMeeting = (
  joinInfo: ChimeJoinInfo | null,
  shouldStart: boolean
): [JoinStatus, (status: JoinStatus | null) => void] => {
  const manager = useMeetingManager();
  const [status, setStatus] = useState<JoinStatus | null>(null);

  useEffect(() => {
    if (!joinInfo) {
      logger.warn("No join info available...");
    }

    if (joinInfo && status == null) {
      setStatus("ready");
    }
  }, [joinInfo, status]);

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    setStatus("joining");

    const start = async () => {
      const { meeting, attendee } = joinInfo as ChimeJoinInfo;
      if (!manager.meetingSession) {
        logger.debug("Joining meeting...");
        const config = new MeetingSessionConfiguration(meeting, attendee);
        await manager.join(config);
        setStatus("joined");
      }

      if (shouldStart) {
        logger.debug("Starting meeting...");
        await manager.start();
        setStatus("started");
        logger.info("Chime meeting started successfully.");
      }
    };

    const fail = (err: Error) => {
      logger.error("Unable to start Chime meeting", err);
      setStatus("failed");
    };

    start().catch(fail);
  }, [joinInfo, status, setStatus]);

  return [status ?? "joining", setStatus];
};

export { useChimeMeeting };
