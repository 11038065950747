export const attendeeJoined = /* GraphQL */ `
  subscription AttendeeJoined($callId: ID!) {
    attendeeJoined(callId: $callId) {
      callId
      call {
        host {
          id
          joined
          profile {
            alias
          }
        }
        attendee {
          id
          joined
          name {
            first
          }
        }
      }
    }
  }
`;
