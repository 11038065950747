import { useState } from "react";
import { Linking } from "react-native";
import styled from "styled-components/native";

const StyledLink = styled.Text<{ isHovered: boolean }>`
  color: ${({ theme, isHovered }) =>
    isHovered ? theme.palette.accent2 : theme.palette.accent1};
  transition: color ${({ theme }) => theme.duration.time2} ease-out;
`;

interface LinkProps {
  to: string;
  children: React.ReactNode;
}

const Link = ({ children, to, ...rest }: LinkProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledLink
      isHovered={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onPress={() => Linking.openURL(to)}
      {...rest}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
