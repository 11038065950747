import { useState, useEffect } from "react";

const url = "https://nearest-media-region.l.chime.aws";

const getNearestMediaRegion = async (): Promise<string> => {
  const response = await fetch(url);

  const { region } = await response.json();

  return region;
};

const useNearestMediaRegion = (): string | null => {
  const [nearestMediaRegion, setNearestMediaRegion] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (nearestMediaRegion) {
      return;
    }

    getNearestMediaRegion().then(setNearestMediaRegion);
  }, [nearestMediaRegion, setNearestMediaRegion]);

  return nearestMediaRegion;
};

export { useNearestMediaRegion };
