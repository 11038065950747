import React from "react";
import { ActivityIndicator } from "react-native";
import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import { createURL } from "expo-linking";
import { Amplify, Logger } from "aws-amplify";
import { NavigationContainer } from "@react-navigation/native";
import { MeetingProvider } from "@tazet/react-native-chime";
import { ThemeProvider as ChimeThemeProvider } from "styled-components";
import { ThemeProvider } from "styled-components/native";
import { RecoilRoot } from "recoil";
import {
  useFonts,
  Mada_400Regular,
  Mada_500Medium,
  Mada_600SemiBold,
} from "@expo-google-fonts/mada";

import { light, extendedChime } from "./common/theme";
import RootNavigator, { Route } from "./navigators/root";

const apiEndpoint = Constants.expoConfig?.extra?.apiEndpoint;
const region = Constants.expoConfig?.extra?.region;

if (!apiEndpoint) {
  throw new Error(
    "No API endpoint set. Add extra configuration in app.config.ts"
  );
}

if (!region) {
  throw new Error(
    "No AWS region set. Add extra configuration in app.config.ts"
  );
}

Amplify.configure({
  aws_appsync_graphqlEndpoint: apiEndpoint,
  aws_appsync_region: region,
  aws_appsync_authenticationType: "AWS_LAMBDA",
});

Logger.LOG_LEVEL = "WARN";

if (__DEV__) {
  Logger.LOG_LEVEL = "DEBUG";
}

const config = {
  screens: {
    [Route.NotFound]: "*",
    [Route.Preview]: "preview",
    [Route.Call]: "call",
    [Route.Ended]: "ended",
    [Route.NotStarted]: "not-started",
    [Route.Exited]: "exited",
    [Route.Cancel]: "cancel",
  },
};

const prefixes = [createURL("/")];

const AppContainer: React.FC = () => {
  useFonts({
    Mada_400Regular,
    Mada_500Medium,
    Mada_600SemiBold,
  });

  return (
    <React.StrictMode>
      <RecoilRoot>
        <ThemeProvider theme={light}>
          <ChimeThemeProvider theme={extendedChime}>
            <MeetingProvider>
              <NavigationContainer
                linking={{ prefixes, config }}
                fallback={<ActivityIndicator />}
                documentTitle={{ formatter: () => "Tazet" }}
              >
                <RootNavigator />
                <StatusBar style="auto" />
              </NavigationContainer>
            </MeetingProvider>
          </ChimeThemeProvider>
        </ThemeProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default AppContainer;
