import styled from "styled-components";
import { fontBaseRegular } from "../../styles";

export const Text = styled.p`
  ${fontBaseRegular};
`;

export const CancelCallLoading = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
