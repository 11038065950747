import { useEffect } from "react";
import { useMeetingManager } from "@tazet/react-native-chime/src/index.web";

export const useAudioVideoHealthChecks = () => {
  const {audioVideo} = useMeetingManager();
  useEffect(() => {
    if (audioVideo) {
      // console.log('HEALTH: Setting up observers.')
      // audioVideo?.addObserver({
      //   audioVideoDidStart: () => console.log("HEALTH: Audio/Video did start."),
      //   audioVideoDidStartConnecting: (reconnecting) => console.log("HEALTH: Audio video did start connecting. Reconnecting: ", reconnecting),
      //   audioVideoDidStop: (status) => console.log("HEALTH: Audio/Video did stop. Session status:", status),
      //   audioVideoWasDemotedFromPrimaryMeeting: (status) => console.log("HEALTH: Audio/Video was demoted from primary meeting. Session status:", status),
      //   connectionDidSuggestStopVideo: () => console.log("HEALTH: Audio/Video did suggest stopping video."),
      //   connectionHealthDidChange: (healthdata) => console.log("HEALTH: Health did change. Health data:", healthdata),
      //   encodingSimulcastLayersDidChange: (simlayers) => console.log("HEALTH: Encoding simulcast layers changed: ", simlayers),
      //   metricsDidReceive: (metrics) => console.log("HEALTH: Metrics received. Metrics: ", metrics),
      //   remoteVideoSourcesDidChange: (video) => console.log("HEALTH: Remote source changed: ", video),
      //   videoAvailabilityDidChange: (availability) => console.log("HEALTH: Video availability changed: ", availability),
      //   videoSendDidBecomeUnavailable: () => console.log("HEALTH: Video send did become available."),
      //   videoTileDidUpdate: (tilestate) => console.log("HEALTH: Video tile did update. Tile state:", tilestate),
      //   videoTileWasRemoved: (tilenumber) => console.log("HEALTH: Video tile was removed. Tile number: ", tilenumber),
      // });
    }
  }, [audioVideo]);

  return null;
};