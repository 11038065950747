import { API } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { atom, useRecoilState } from "recoil";
import {
  JoinCallMutation,
  JoinCallMutationVariables,
  JoinLobbyMutation,
  JoinLobbyMutationVariables,
} from "../../api";
import { joinCall, joinLobby } from "../../graphql/mutations";
import { ChimeJoinInfo } from "../types/chime";
import { useCallback, useEffect, useState } from "react";
import { useNearestMediaRegion } from "./media-region";
import { useNavigation, useRoute } from "@react-navigation/native";
import { GraphQLErrorResponse } from "../types/gql";
import { Route } from "../../navigators/root";

const previewJoinInfoState = atom<ChimeJoinInfo | null>({
  key: "preview.joininfo",
  default: null,
});

const callJoinInfoState = atom<ChimeJoinInfo | null>({
  key: "call.joininfo",
  default: null,
});

const getPreviewJoinInfo = async (
  id: string,
  secret: string,
  mediaRegion: string
): Promise<ChimeJoinInfo> => {
  const query = joinLobby;
  const variables = {
    input: { id, mediaRegion },
  } as JoinLobbyMutationVariables;
  const authToken = `${id}:${secret}`;

  const response = (await API.graphql({
    query,
    variables,
    authToken,
  })) as GraphQLResult<JoinLobbyMutation>;

  const joinInfo = response.data?.joinLobby.joinInfo;
  const meetingInfo = joinInfo?.meetingInfo;
  const attendeeInfo = joinInfo?.attendeeInfo;

  if (!meetingInfo || !attendeeInfo) {
    throw new Error("Unable to obtain Chime join info for preview.");
  }

  return {
    meeting: JSON.parse(meetingInfo),
    attendee: JSON.parse(attendeeInfo),
  };
};

const getCallJoinInfo = async (
  id: string,
  secret: string,
  mediaRegion: string
): Promise<ChimeJoinInfo> => {
  const query = joinCall;
  const variables = {
    input: { id, mediaRegion },
  } as JoinCallMutationVariables;
  const authToken = `${id}:${secret}`;

  const response = (await API.graphql({
    query,
    variables,
    authToken,
  })) as GraphQLResult<JoinCallMutation>;
  const joinInfo = response.data?.joinCall.joinInfo;
  const meetingInfo = joinInfo?.meetingInfo;
  const attendeeInfo = joinInfo?.attendeeInfo;

  if (!meetingInfo || !attendeeInfo) {
    throw new Error("Unable to obtain Chime join info for call.");
  }

  return {
    meeting: JSON.parse(meetingInfo),
    attendee: JSON.parse(attendeeInfo),
  };
};

type JoinInfoResult = [boolean, ChimeJoinInfo | null];

const usePreviewJoinInfo = (): JoinInfoResult => {
  const route = useRoute();
  const mediaRegion = useNearestMediaRegion();
  const [loading, setLoading] = useState(false);
  const [previewJoinInfo, setPreviewJoinInfo] =
    useRecoilState(previewJoinInfoState);

  const join = useCallback(async () => {
    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };
    const joinInfo = await getPreviewJoinInfo(
      callId,
      callSecret,
      mediaRegion as string
    );
    setPreviewJoinInfo(joinInfo);
  }, [route, mediaRegion, setPreviewJoinInfo]);

  useEffect(() => {
    if (loading || !mediaRegion || previewJoinInfo) {
      return;
    }

    setLoading(true);

    join().finally(() => setLoading(false));
  }, [loading, setLoading, mediaRegion, join, previewJoinInfo]);

  return [loading, previewJoinInfo];
};

const useCallJoinInfo = (): JoinInfoResult => {
  const route = useRoute();
  const navigation = useNavigation();
  const mediaRegion = useNearestMediaRegion();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<GraphQLErrorResponse>();
  const [callJoinInfo, setCallJoinInfo] = useRecoilState(callJoinInfoState);

  const join = useCallback(async () => {
    const { c: callId, s: callSecret } = route.params as {
      c: string;
      s: string;
    };
    const joinInfo = await getCallJoinInfo(
      callId,
      callSecret,
      mediaRegion as string
    );
    setCallJoinInfo(joinInfo);
  }, [route, mediaRegion, setCallJoinInfo]);

  useEffect(() => {
    if (loading || !mediaRegion || callJoinInfo || error) {
      return;
    }

    setLoading(true);

    join()
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [loading, setLoading, mediaRegion, join, callJoinInfo]);

  useEffect(() => {
    if (error?.errors.some((e) => e.message === "Call has already ended")) {
      // @ts-ignore
      navigation.navigate(Route.Ended, route.params);
    }
  }, [error]);

  return [loading, callJoinInfo];
};

export { usePreviewJoinInfo, useCallJoinInfo };
