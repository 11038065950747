import { DefaultTheme } from "styled-components";
import { lightTheme } from "@tazet/react-native-chime";

const light: DefaultTheme = {
  mode: "light",
  background: "#f2f4f5",
  foreground: "#ffffff",
  text: {
    primary: "#071217",
    secondary: "#919ba2",
    warning: "#def62e",
  },
  button: {
    affirmative: "#1a8cdd",
  },
  palette: {
    base1: "#0f191f",
    base2: "#2f373d",
    base3: "#565f66",
    base4: "#7e898f",
    base5: "#909ba3",
    base6: "#bfc4c7",
    base7: "#dcdfe0",
    base8: "#e8eaeb",
    base9: "#f0f1f2",
    base10: "#f5f6f7",
    base11: "#ffffff",
    accent1: "#148fcc",
    accent1a: "#17a1e6",
    accent2: "#f14b8d",
    accent3: "#27c28e",
    accent3a: "#25e5a5",
    accent4: "#faaf5a",
    accent4a: "#fafa3e",
    accent5: "#ff8533",
    accent6: "#00c4d5",
  },
  duration: {
    time1: "64ms",
    time2: "128ms",
    time3: "256ms",
    time4: "512ms",
    time5: "768ms",
  },
  durationNumbers: {
    time1: 64,
    time2: 128,
    time3: 256,
    time4: 512,
    time5: 768,
  },
  easing: {
    easeOutMax: "cubic-bezier(0.165, 0.84, 0.44, 1)",
  },
  font: "Mada",
};

const extendedChime = {
  ...lightTheme,
  ...light,
};

export { light, extendedChime };
