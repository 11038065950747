import Constants from 'expo-constants';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

const configureCwr = (app_id: string, app_version: string, app_region: string, config: AwsRumConfig): AwsRum | null => {
    try {
        return new AwsRum(
            app_id,
            app_version,
            app_region,
            config
        );
    } catch (error) {
        console.error(error);
        return null;
    }
};

const app_version = Constants.expoConfig?.version;
const region = Constants.expoConfig?.extra?.region;

const app_id = Constants.expoConfig?.extra?.appMonitorId;
const guestRoleArn = Constants.expoConfig?.extra?.guestRoleArn;
const identityPoolId = Constants.expoConfig?.extra?.identityPoolId;

if (!app_id || !app_version || !region || !guestRoleArn || !identityPoolId) {
    throw new Error('Missing required configuration for AWS RUM. Please check app.config.ts.');
}

const config: AwsRumConfig = {
    enableXRay: true,
    guestRoleArn,
    identityPoolId,
    sessionSampleRate: 1,
    enableRumClient: !__DEV__,
    telemetries: ["http", "performance", "errors"]
};

const cwr = configureCwr(app_id, app_version, region, config);

export default cwr;