/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const joinLobby = /* GraphQL */ `
  mutation ($input: JoinLobbyInput!) {
    joinLobby(input: $input) {
      call {
        id
        subject
        start
        end
      }
      joinInfo {
        meetingInfo
        attendeeInfo
      }
    }
  }
`;

export const joinCall = /* GraphQL */ `
  mutation ($input: JoinCallInput!) {
    joinCall(input: $input) {
      callId
      call {
        host {
          id
          joined
          profile {
            alias
          }
        }
        attendee {
          id
          joined
          name {
            first
          }
        }
      }
      joinInfo {
        meetingInfo
        attendeeInfo
      }
    }
  }
`;

export const cancelCall = /* GraphQL */ `
  mutation ($input: CancelCallInput!) {
    cancelCall(input: $input) {
      call {
        id
        cancelled {
          at
          by
          note
        }
      }
    }
  }
`;
