import { forwardRef } from "react";
import styled from "styled-components";
import classnames from "classnames";
import { Twurl } from "../Twurl/index.web";
import { fontBaseMedium, lightenSaturate, transition } from "../../../styles";

type ButtonVariant = "primary" | "outlined";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  compact?: boolean;
  inline?: boolean;
  label: string;
  loading?: boolean;
  variant?: ButtonVariant;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = "",
      compact = false,
      inline = false,
      label,
      loading = false,
      variant = "primary",
      ...rest
    },
    ref
  ) => (
    <StyledButton
      ref={ref}
      className={classnames({ loading, inline, compact }, variant, className)}
      {...rest}
    >
      <Twurl
        variant={variant === "outlined" ? "dark" : "light"}
        visible={loading}
      />
      <div className="label">{label}</div>
    </StyledButton>
  )
);

const StyledButton = styled.button`
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.03));
  background-color: ${({ theme }) => theme.palette.accent1a};
  border: 1px solid transparent;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 3px 9px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  ${fontBaseMedium};
  color: ${({ theme }) => theme.palette.base11};
  font-size: 16px;
  padding: 16px 0;
  position: relative;
  flex-grow: 1;
  ${({ theme }) =>
    transition(
      theme.duration.time2,
      "ease-out",
      "background-color",
      "box-shadow",
      "border-color"
    )};
  width: 100%;

  &:hover:not(:disabled):not(:focus) {
    background-color: ${({ theme }) =>
      lightenSaturate(theme.palette.accent1a, 0.02, 0.02)};
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }

  &.outlined {
    background: none;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    color: ${({ theme }) => theme.palette.base1};

    &:hover:not(:disabled):not(:focus) {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.28);
    }

    &:focus:not(:active) {
      border: 1px dashed ${({ theme }) => theme.palette.base5};
    }
  }

  &.inline {
    display: inline-block;
    flex-grow: 0;
    font-size: 14px;
    padding: 10px 24px;
    width: auto;

    & + & {
      margin-left: 10px;
    }
  }

  &.compact {
    font-size: 12px;
    padding: 6px 14px 5px;
  }

  &.loading {
    cursor: default;

    div.label {
      opacity: 0;
    }
  }
`;

export default Button;
