export const getCall = /* GraphQL */ `
  query {
    getCall {
      id
      subject
      start
      duration
      host {
        id
        joined
        profile {
          alias
        }
      }
      attendee {
        id
        joined
        name {
          first
        }
      }
      cancelled {
        at
        by
        note
      }
    }
  }
`;

export const getIdentity = /* GraphQL */ `
  query {
    whoami {
      id
      joined
    }
  }
`;
