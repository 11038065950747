import { useEffect, useState } from "react";
import { getDeviceTypeAsync, DeviceType } from "expo-device";

const useDeviceType = (): DeviceType | null => {
  const [deviceType, setDeviceType] = useState<DeviceType | null>(null);

  useEffect(() => {
    if (!deviceType) {
      getDeviceTypeAsync().then(setDeviceType);
    }
  }, [setDeviceType]);

  return deviceType;
};

export { useDeviceType };
