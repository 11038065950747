import React, { useMemo, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Route } from "../../../navigators/root";
import { useCall, useIdentity } from "../../hooks";
import { ActivityIndicator } from "react-native";
import { CallIdentity } from "../../types";
import { isDev } from "../../consts";

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const CallValidatorLoader = () => (
  <LoadingWrapper>
    <ActivityIndicator />
  </LoadingWrapper>
);

interface CallValidatorProps {
  children: (args: CallIdentity) => React.ReactNode;
}

export const CallValidator = ({ children }: CallValidatorProps) => {
  const route = useRoute();
  const [call] = useCall();
  const [identity] = useIdentity();
  const navigation = useNavigation();

  const isCancelled = useMemo(() => !!call?.cancelled, [call]);

  // @ts-ignore
  const isValidOverride = isDev && route?.params?.override;

  const isEarly = useMemo(
    () => (call ? moment(call.start).diff(moment()) > 600000 : false),
    [call]
  );

  const isEnded = useMemo(
    () =>
      call
        ? moment(call.start)
            .add(moment.duration(call.duration))
            .diff(moment()) <= 0
        : false,
    [call]
  );

  useEffect(() => {
    if (isValidOverride) return;

    let nextRoute;
    if (isCancelled) nextRoute = Route.Cancel;
    if (isEnded) nextRoute = Route.Ended;
    if (isEarly) nextRoute = Route.NotStarted;

    // @ts-ignore
    if (nextRoute) navigation.navigate(nextRoute, route.params);
  }, [isEnded, isEarly]);

  useEffect(() => {
    if (call && identity) {
      const isAttendee = identity?.id === call.attendee.id;
      document.title = `Tazet call with ${
        isAttendee ? call.host.name : call.attendee.name
      }`;
    }
  }, [call, identity]);

  return call && identity ? (
    <>{children({ call, identity, isEarly, isEnded })}</>
  ) : (
    <CallValidatorLoader />
  );
};
